// React
import React, { useState, useContext } from 'react';
// Reach Router
import { Link, navigate } from "@reach/router"
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Button, List, ListItem, ListItemIcon, ListItemText, Typography, IconButton, Drawer } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
// Local
import AppContext from './AppContext';


const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flex: 'auto',
      flexDirection: 'column',
      height: '100%',
    },

    // Header
    header: {
      // Height
      height: '56px',
      flexGrow: 0,
      flexShrink: 0,
      // Children
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      // Other
      backgroundColor: theme.palette.primary.main,
    },
    backButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      
      width: 100,
      paddingTop: 'initial',
      paddingBottom: 'initial',
      [theme.breakpoints.down('xs')]: {
        width: 30,
        fontSize: 0,
      },
    },
    menuButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      
      width: 30,
      paddingTop: 'initial',
      paddingBottom: 'initial',
      marginRight: theme.spacing(1),
    },
    backIcon: {
      marginRight: -12,
    },
    title: {
      color: theme.palette.primary.contrastText,
    },

    // Main
    main: {
      // Height
      overflowY: 'auto',
      flexGrow: 1,
      flexShrink: 1,
      // Width
      width: '100%',
    },
    // Main
    mainInner: {
      // Width
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.md - theme.spacing(2) * 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
      },
      // Interior
      display: 'flex',
      flexDirection: 'column',
    },



  list: {
    height: '100%',
    width: 200,
  },
  listIcon: {
    minWidth: '40px',
  },
  spacer: {
    flexGrow: 1,
  },
  signOutItem: {
    position: 'absolute',
    bottom: 0,
    width: '100%', 
  },
}));

export default function EmployeeFrame({children, title, backLabel, backTo, selectedItem}) {
  const {doSignOut} = useContext(AppContext);
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleSignOutClick = (event) => {
    event.preventDefault();
    doSignOut();
  };

  const header = (
      <header className={classes.header}>
        { backLabel && backTo ?
          <Button color="inherit" onClick={(e) => navigate(backTo)} className={classes.backButton} 
                  startIcon={<ArrowBackIosIcon className={classes.backIcon} />}>
            {backLabel}
          </Button>
          :
          <div className={classes.backButton} />
        }
        <Typography variant="h2" className={classes.title}>{title}</Typography>
        <IconButton onClick={() => setIsDrawerOpen(true)} className={classes.menuButton}  ><MenuIcon /></IconButton>
      </header>
    );

  const drawerContent = (
    <List className={classes.list}>
        <ListItem button onClick={(event) => setIsDrawerOpen(false)} >
          <ListItemIcon className={classes.listIcon} >{<NavigateNextIcon />}</ListItemIcon>
        </ListItem>
        <ListItem button component={Link} to='/' selected={selectedItem === 'home'} >
          <ListItemIcon className={classes.listIcon} >{<HomeIcon />}</ListItemIcon>
          <ListItemText>Home</ListItemText>
        </ListItem>
        <ListItem button component={Link} to='/submissions' selected={selectedItem === 'submissions'} >
          <ListItemIcon className={classes.listIcon} >{<PeopleIcon />}</ListItemIcon>
          <ListItemText>Submissions</ListItemText>
        </ListItem>
        <ListItem button component={Link} to='/clients' selected={selectedItem === 'clients'} >
          <ListItemIcon className={classes.listIcon} >{<PeopleIcon />}</ListItemIcon>
          <ListItemText>Clients</ListItemText>
        </ListItem>

        <ListItem button onClick={(event) => handleSignOutClick(event)} className={classes.signOutItem} >
          <ListItemIcon className={classes.listIcon} >{<ExitToAppIcon />}</ListItemIcon>
          <ListItemText>Sign Out</ListItemText>
        </ListItem>
    </List>
  );

  return (
    <div className={classes.container}>
      {header}
      <main className={classes.main}>
        <div className={classes.mainInner}>
          {children}
        </div>
      </main>
      <Drawer anchor='right' open={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} >
        {drawerContent}
      </Drawer>
    </div>
  );
}

