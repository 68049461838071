// React
import React, { useState, useContext, useEffect } from 'react';
// Reach Router
import { navigate } from '@reach/router';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, FormControl, FormLabel, TextField } from '@material-ui/core';
import { GetApp as GetAppIcon } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
// Local
import AppContext from '../AppContext';
import FullScreenPopup from '../shared/FullScreenPopup';
import MyFileViewer from '../shared/MyFileViewer';
import MyFileUpload from '../shared/MyFileUpload';
import LoadingOverlay from '../shared/LoadingOverlay';
import { MyRadioGroup, Checklist } from '../shared/controls';
import SectionHeaderRow from '../shared/SectionHeaderRow';



const styles = (theme) => ({

  container: {
    backgroundColor: '#f0f0f0',
  },

  main: {
    // Width
    width: '100%',
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      width: theme.breakpoints.values.md - theme.spacing(2) * 2,
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: 0,
      paddingRight: 0,
    },

    display: 'flex',
    flexDirection: 'column',
  },

  statusRow: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  statusDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  subTitleRow: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  detailText: {
    fontStyle: 'italic',
  },

  footerSaveButton: {
    width: '90px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});
const useStyles = makeStyles(styles);


export default function ClientDocument({submissionId, clientUploadId, clientUploads}) {
  const {db, storage, functions, loggedInUser, reference} = useContext(AppContext);
  const classes = useStyles();

  const refClientUploadStatus = reference['client-upload-status'];
  const [clientUpload, setClientUpload] = useState(undefined);
  const [refDocType, setRefDocType] = useState(undefined);

  const [showLoading, setShowLoading] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [viewerFilePath, setViewerFilePath] = useState(undefined);
  const [viewerContentType, setViewerContentType] = useState(undefined);

  useEffect(() => {
    if( clientUploads === undefined || clientUploadId === undefined )
      setClientUpload(undefined);
    else
      setClientUpload(clientUploads.find(e => e.id === clientUploadId));
  }, [clientUploads, clientUploadId]);

  useEffect(() => {
    if( reference === undefined || clientUpload === undefined )
      setRefDocType(undefined);
    else
      setRefDocType(reference['doc-types'][clientUpload.shared.docType]);
  }, [reference, clientUpload]);

  const reflect = p => p.then(v => ({v, status: "fulfilled" }), e => ({e, status: "rejected" }));
  const handleUpload = (selectedItems) => {
    if( selectedItems.length === 0 )
      return;

    setShowLoading(true);

    const tempId = Math.random().toString(36).substring(2, 15);

    let promises = selectedItems.map( (item) => {
      return storage.ref('user-temp/' + loggedInUser.uid + '/' + tempId + '/' + item.name ).put(item);
    });

    Promise.all(promises.map(reflect)).then( (results) => {
      let newAlerts = [];

      results.forEach( (x, index) => {
        if( x.status === 'rejected' )
          newAlerts.push( 'Unable to upload file "' + selectedItems[index].name + '".' );
      } )
     
      const data = { submissionId: submissionId, clientUploadId: clientUploadId, tempDirName: tempId };
      functions.commitClientUploadFiles(data).then( (result) => {
        setAlerts(newAlerts);
        setShowLoading(false);
      })
      .catch( (error) => {
        console.error("Error committing document", error);
        setShowLoading(false);
      });

    });
    
  }

  const handleClose = () => {
    navigate(-1);
  }

  const handleDownloadTemplateClick = (e) => {
    e.preventDefault();
    storage.ref('/public/templates/' + refDocType.templateName)
    .getDownloadURL().then( (url) => {
      window.open(url, "_blank");
    }).catch(function(error) {
      console.log(error);
    });
    return false;
  }

  const handleViewFile = (fileDesc) => {
    storage.ref(fileDesc.path).getDownloadURL().then( (url) => {
      setViewerFilePath(url);
      setViewerContentType(fileDesc.contentType);
    }).catch(function(error) {
      console.log(error);
    });
    return false;
  }

  const handleDownloadFile = (fileDesc) => {
    storage.ref(fileDesc.path).getDownloadURL().then( (url) => {
      window.open(url, "_blank");
    }).catch(function(error) {
      console.log(error);
    });
    return false;
  }

  const handleFileDelete = (fileDesc) => {
    setShowLoading(true);
    const data = { submissionId: submissionId, clientUploadId: clientUploadId, fileName: fileDesc.name };
    functions.deleteClientUploadFile(data).then( (result) => {
      setShowLoading(false);
    })
    .catch( (error) => {
      console.error("Error deleting file", error);
      setShowLoading(false);
    });
  }

  const handleOnChange = (name, value) => {
    const update = { [name]: value };
    db.collection('submissions_shared').doc(submissionId).collection('client_uploads').doc(clientUploadId).update(update).catch( (error) => {
      console.error(error);
    })
  };


  if( clientUpload === undefined )
    return ( <FullScreenPopup title='Loading' onCancel={() => handleClose()}           footerRight={<Button onClick={(e) => handleClose()} color='primary' variant='contained' className={classes.footerSaveButton} >Close</Button>} >
        <div>Loading...</div>
       </FullScreenPopup> );
  else {

    return (
      <FullScreenPopup  title={clientUpload.shared?.label || refDocType?.label || 'Unknown'} onCancel={() => handleClose()} 
          footerRight={<Button onClick={(e) => handleClose()} color='primary' variant='contained' className={classes.footerSaveButton} >Close</Button>} >
        <div className={classes.container} >
          <div className={classes.main} >

            <div className={classes.statusRow}>
              <div className={classes.statusDiv}>
                <Typography variant="body1" className={classes.subTitle}>Status: </Typography>&nbsp;&nbsp;&nbsp;
                <Typography variant="body1"
                              style={{textTransform: 'uppercase',
                                     color: refClientUploadStatus[clientUpload.status].color }}
                               >{refClientUploadStatus[clientUpload.status].label}</Typography>
              </div>
              { refDocType && refDocType.templateName &&
                <Button variant="outlined" component="span" 
                      onClick={(event) => handleDownloadTemplateClick(event) }
                      startIcon={<GetAppIcon />}>
                  Template
                </Button>
              }
            </div>

            { 
              alerts.map( (alert) => {
                return (
                    <Alert key={alert} variant="filled" severity="error" 
                        onClose={(event) => { 
                          const newAlerts = [...alerts];
                          const pos = newAlerts.findIndex((el) => el === alert);
                          newAlerts.splice(pos,1);
                          setAlerts(newAlerts);
                        }}>
                      {alert}
                    </Alert>
                  );
              } )
            }

            <SectionHeaderRow text='Files' />
            <MyFileUpload
              files={clientUpload.shared.files}
              isReadOnly={clientUpload.shared.isAccepted}
              onUploadRequested={selectedFiles => handleUpload(selectedFiles)}
              onDeleteRequested={fileDesc => handleFileDelete(fileDesc)}
              onViewFileRequested={fileDesc => handleViewFile(fileDesc)}
              onDownloadFileRequested={fileDesc => handleDownloadFile(fileDesc)}
            />

            <SectionHeaderRow text='Requirements' />
            <Checklist  items={clientUpload.shared.checklist || []} 
                        values={clientUpload.shared.checklistResults || []}
                        onChange={ (value) => handleOnChange( "checklistResults", value ) } />

            <SectionHeaderRow text='Review' />
            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Result</FormLabel>
              <MyRadioGroup value={clientUpload.shared.reviewResult || ''} 
                            options={ [ {value:'accepted', label:'Accepted'}, 
                                        {value:'rejected', label:'Needs Attention'}, 
                                        {value:'', label:'Neither'} ] }
                            onChange={ (value) => handleOnChange( "reviewResult", value ) } />
            </FormControl>
            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Comment</FormLabel>
              <TextField          
                id="reviewComment"
                name="reviewComment"
                value={clientUpload.shared.reviewComment || ''}
                type="text"
                className={classes.textFieldWide}
                onChange={(event) => handleOnChange('reviewComment', event.target.value)}
              />
            </FormControl>

          </div>

        </div>

        <MyFileViewer 
            open={viewerFilePath !== undefined}
            file={viewerFilePath} 
            contentType={viewerContentType}
            onClose={() => { setViewerFilePath(undefined); setViewerContentType(undefined); }} />

        <LoadingOverlay open={showLoading} />
      </FullScreenPopup>

    );
  }
}

/*











*/
