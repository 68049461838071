// React
import React, { useState, useContext, useEffect } from 'react';
// Reach Router
import { navigate } from '@reach/router';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, Button, TextField } from '@material-ui/core';
// Local
import AppContext from '../AppContext';
import FullScreenPopup from '../shared/FullScreenPopup';
import { MyRadioGroup, MyEditableList } from '../shared/controls';
import ClientUploadClass from '../shared/model/ClientUploadClass';
import SectionHeaderRow from '../shared/SectionHeaderRow';


import formPageStyles from '../shared/style/FormPageStyles';
import { setDeep } from '../shared/Util';


const styles = (theme) => ({
    container: {
      // Children
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },

      backgroundColor: '#FFFFFF',
    },

    footerBackButton: {
      backgroundColor: theme.palette.grey[300],
      width: '90px',
    },
    footerCloseButton: {
      //3backgroundColor: theme.palette.grey[300],
      width: '130px',
    },
    footerSubmitButton: {
      width: '90px',
    },
});
const mergeStyles = (theme) => {
  const local = styles(theme);
  const form = formPageStyles(theme);
  return Object.assign(form, local);
}
const useStyles = makeStyles(mergeStyles);


export default function ClientDocumentRequest({submissionId, clientUploadId, clientUploads}) {
  const {db, reference} = useContext(AppContext);
  const refDocTypes = reference['doc-types'];
  const classes = useStyles();
  const docTypeRadioOptions = Object.keys(refDocTypes)
          .map( (docTypeId) => ({ value: docTypeId, label: refDocTypes[docTypeId].label }) )
          .sort( (a,b) => { return refDocTypes[a.value].sortOrder - refDocTypes[b.value].sortOrder } )
          .concat( { value: '', label: 'None' } );
  const [clientUpload, setClientUpload] = useState(undefined);

  useEffect(() => {
    if( clientUploads === undefined || clientUploadId === undefined )
      setClientUpload(undefined);
    else if( clientUploadId === 'new' )
      setClientUpload(new ClientUploadClass());
    else {
      const newClientUpload = clientUploads.find(e => e.id === clientUploadId);
      if( newClientUpload )
        setClientUpload(newClientUpload);
      else
        setClientUpload('not-found');
    }
  }, [clientUploads, clientUploadId]);

  const handleClose = () => {
    navigate(-1);
  }
  const handleSaveAndClose = () => {
    var newClientUploadRef;
    if( clientUploadId === 'new' )
      newClientUploadRef = db.collection('submissions_shared').doc(submissionId).collection('client_uploads').doc();
    else
      newClientUploadRef = db.collection('submissions_shared').doc(submissionId).collection('client_uploads').doc(clientUploadId);
    
    const clientUploadToSave = clientUpload.toSharedPOJO();
    console.log(clientUpload);
    console.log(clientUploadToSave);
    newClientUploadRef.set(clientUpload.toSharedPOJO())
    .then( (docRef) => {
      handleClose();
    })
    .catch( (error) => {
      console.error("Error adding document", error);
    })
  }

  const handleOnChangeEvent = (event) => {
    event.preventDefault();
    handleOnChange(event.target.name, event.target.value);
  }
  const handleOnChange = (name, value) => {
    let newClientUpload = clientUpload.clone();
    setDeep( newClientUpload, name, value );

    // If the doc type changes, default other fields
    if( name === 'shared.docType' ) {
      if( value === '' ) {
        newClientUpload.shared.label = '';
        newClientUpload.shared.checklist = [];
        newClientUpload.shared.checklistResults = [];
      }
      else {
        const refDocType = refDocTypes[value];
        newClientUpload.shared.label = refDocType.label;
        newClientUpload.shared.checklist = refDocType.checklist || [];
        newClientUpload.shared.checklistResults = Array(newClientUpload.shared.checklist.length).fill(false);
      }
    }

    if( name === 'shared.checklist' )
      newClientUpload.shared.checklistResults = Array(newClientUpload.shared.checklist.length).fill(false);

    setClientUpload( newClientUpload );
  };

  //console.log(clientUpload);

  if( clientUpload === undefined )
    return ( <FullScreenPopup title='Loading' onCancel={() => handleClose()}
        footerRight={[<Button key={1} onClick={(event) => handleClose()} className={classes.footerCloseButton}>Cancel</Button>,
                      <Button key={2} onClick={(event) => handleSaveAndClose()} color='primary' variant='contained' className={classes.footerCloseButton} >Save</Button>]} >
                        <div>Loading...</div>
                      </FullScreenPopup> );
  else {
    const docType = clientUpload.shared.docType;

    return (
      <FullScreenPopup  title='New Document' onCancel={() => handleClose()}
        footerRight={[<Button key={1} onClick={(event) => handleClose()} className={classes.footerCloseButton}>Cancel</Button>,
                      <Button key={2} onClick={(event) => handleSaveAndClose()} color='primary' variant='contained' className={classes.footerCloseButton} >Save</Button>]} >
        <div className={classes.container} >
          <div className={classes.pageContent} >

            <SectionHeaderRow text='Details' />
            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Document Type</FormLabel>
              <MyRadioGroup value={clientUpload.shared.docType || ''} 
                            options={ docTypeRadioOptions }
                            onChange={ (value) => handleOnChange( "shared.docType", value ) }/>
            </FormControl>
            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Name</FormLabel>
              <TextField          
                id="label"
                name="shared.label"
                value={clientUpload.shared.label || ''}
                type="text"
                className={classes.textField}
                onChange={(event) => handleOnChangeEvent(event)}
              />
            </FormControl>
            { docType !== undefined && docType !== 'new' && refDocTypes[docType]?.templateName &&
              <FormControl component="fieldset" className={classes.field}>
                <FormLabel component="legend">Template</FormLabel>
                <TextField          
                  type="text"
                  value={refDocTypes[docType].templateName}
                  disabled={true}
                  className={classes.textField} 
                />
              </FormControl>
            }
          </div>

          <SectionHeaderRow text='Checklist' />
          <MyEditableList values={clientUpload.shared.checklist} 
                          onChange={(value) => handleOnChange('shared.checklist', value)}
                          style={{ width: '500px' }} />

        </div>
      </FullScreenPopup>
    );
  }
}
