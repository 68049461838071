// React
import React from 'react';
// Material UI
import { TextField, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
// Local
import { SquareIconButton } from '.';


export const MyEditableList = ({values, onChange, style}) => {

  const handleOnChangeEvent = (event) => {
    event.stopPropagation();
    var newValues = [...values];
    newValues[event.target.name] = event.target.value;
    if( onChange !== undefined )
      onChange(newValues)
  }

  const handleDeleteClick = (event, index) => {
    var newValues = [...values];
    newValues.splice(index, 1);
    if( onChange !== undefined )
      onChange(newValues)
  }

  const handleAddClick = () => {
    const newValues = [ ...values, ''];
    if( onChange !== undefined )
      onChange(newValues)
  }

  return (
    <div style={ { ...{display: 'flex', flexDirection: 'column', alignItems: 'left'}, ...style } } >  
      { values === undefined || values.length === 0 ? 
        'No items' 
        :         
        values.map( (value, index) => (
            <div key={index} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }} >
              <TextField          
                  id={index.toString()}
                  name={index.toString()}
                  value={value || ''}
                  type="text"
                  onChange={(event) => handleOnChangeEvent(event)}
                  style={{display: 'flex', flexGrow: 1 }}
                />
              <SquareIconButton aria-label="delete" onClick={(event) => handleDeleteClick(event, index) }><DeleteIcon /></SquareIconButton>
            </div>
          )
        )
      }
      <Button onClick={() => handleAddClick()} startIcon={<AddIcon />} style={{marginLeft: '-16px', width: '80px'}} >Add</Button>
    </div>
  );
}
