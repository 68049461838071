// React
import React, { useContext, useState, useEffect } from 'react';
// Material UI
//import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Reach Router
import { navigate } from "@reach/router"
// Local
import AppContext from '../AppContext';
import SubmissionFrame from './SubmissionFrame';
import MyYesNoDialog from '../shared/dialogs/MyYesNoDialog';
import LoadingOverlay from '../shared/LoadingOverlay';
import CardListSection from '../shared/CardListSection';


//const styles = (theme) => ({});
//const useStyles = makeStyles(styles);


export default function SubmissionDocuments({submission, clientUploads, clients}) {
  const {reference, db, storage} = useContext(AppContext);
  //const classes = useStyles();

  const refApplicationStatus = reference['application-status'];
  const refClientUploadStatus = reference['client-upload-status'];
  const refDocTypes = reference['doc-types'];
  const [clientUploadIdToDelete, setClientUploadIdToDelete] = useState(undefined);
  const [showLoading, setShowLoading] = useState(false);
  const [applications, setApplications] = useState(undefined);
  const [isLoadingApplications, setIsLoadingApplications] = useState(undefined);

  useEffect(() => {
    // Merge loan application and client applications into one list for display
    let newApplications = [];
    newApplications.push({
            id: 'loan_' + submission.id, 
            title: 'Loan', 
            status: submission?.shared?.application?.status || 'new',
            url: '/submissions/' + submission.id + '/application' });

    if( submission?.shared !== undefined && clients !== undefined )
      (submission?.shared.clients || []).forEach( clientId => {
        const client = clients.find(e => e.id === clientId);
        newApplications.push({
              id: client.id, 
              title: 'Borrower (' + client?.private?.name + ')', 
              status: client?.shared?.application?.status || 'new',
              url: '/clients/' + client.id + '/application' });
      });

    setApplications(newApplications);
    setIsLoadingApplications(submission?.shared === undefined || clients === undefined );
  }, [submission, clients])
  
  const handleAddClientUpload = () => {
     navigate('client-upload-requests/new');
  }
  const handleEditClientUploadClick = (clientUploadId) => {
    navigate('client-upload-requests/' + clientUploadId);
  }

  const deleteClientUpload = (clientUploadId) => {
    setShowLoading(true);

    // Delete files from storage
    var storageRef = storage.ref();
    storageRef.child('submissions').child(submission.id).child('client-uploads').child(clientUploadId).listAll().then( res => {
      res.items.forEach( itemRef => {
        itemRef.delete().then( (res) => {
          console.log('Deleted client upload file from database', clientUploadId, itemRef);
        }).catch( error => {
          console.error("Error deleting file in storage", error);
          setShowLoading(false);
        });;
      });
    }).catch( error => {
      console.error("Error listing files in storage", error);
      setShowLoading(false);
    });

    // Delete from database
    db.collection('submissions_shared').doc(submission.id).collection('client_uploads').doc(clientUploadId).delete().then( () => {
      console.log('Deleted client upload from database', clientUploadId);
      setShowLoading(false);
    }).catch( error => {
      console.error("Error deleting client upload " + clientUploadId + " from database.", error);
      setShowLoading(false);
    });
  }

  return (
    <SubmissionFrame submissionId={submission.id} >
      <CardListSection
          title='Applications'
          getCardTitle={(value) => value.title}
          getCardUrl={(value) => value.url }
          getCardRight={(value) => <Typography variant="body1" key={1}
                                     style={{ textTransform: 'uppercase',
                                              color: refApplicationStatus[value.status].color }} 
                       >{refApplicationStatus[value.status].label}</Typography>}
          values={applications}
          isLoading={isLoadingApplications}
          />           

      <CardListSection
          title='Client Documents'
          onAdd={() => handleAddClientUpload()}
          getCardTitle={(value) => value.shared.label || refDocTypes[value.shared.docType]?.label || 'Unknown' }
          getCardUrl={(value) => '/submissions/' + submission.id + '/client-uploads/' + value.id }
          getCardRight={(value) => <Typography variant="body1" key={1}
                                     style={{ textTransform: 'uppercase',
                                              color: refClientUploadStatus[value.status].color }} 
                       >{refClientUploadStatus[value.status].label}</Typography>}
          onCardEdit={(value) => handleEditClientUploadClick(value.id)}
          onCardDelete={(value) => setClientUploadIdToDelete(value.id)}
          values={clientUploads}
          />                        

      <MyYesNoDialog
          open={clientUploadIdToDelete !== undefined}
          title="Delete client document"
          message="Proceed to delete the client document?"
          onYes={() => { deleteClientUpload(clientUploadIdToDelete); setClientUploadIdToDelete(); }}
          onNo={() => setClientUploadIdToDelete(undefined)}
      />

      <LoadingOverlay open={showLoading} />

    </SubmissionFrame>
  );

}
