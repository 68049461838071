// React
import React, { useContext } from 'react';
// Material UI
//import { makeStyles } from '@material-ui/core/styles';
//import { } from '@material-ui/core';
// Reach Router
import {  navigate } from "@reach/router"
// Local
import AppContext from '../AppContext';
import EmployeeFrame from '../EmployeeFrame';
import CardListSection from '../shared/CardListSection';


//const styles = (theme) => ({});
//const useStyles = makeStyles(styles);


export default function Home({clients}) {
  const {db, loggedInUser, firebase} = useContext(AppContext);
//  const classes = useStyles();

  const handleAddNewClient = () => {
    db.collection('clients').add({
      createdBy: loggedInUser.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then( (docRef) => {
      const clientId = docRef.id;
      navigate('/clients/' + clientId);
    })
    .catch( (error) => {
      console.error("Error adding client", error);
    })
  };

  return (
    <EmployeeFrame title='Clients' backLabel='Home' backTo='/' >

      <CardListSection
          title='Clients'
          onAdd={() => handleAddNewClient()}
          getCardTitle={(value) => value?.private?.name || '<Unnamed>' }
          getCardUrl={(value) => '/clients/' + value.id }
          values={clients}
          />   

    </EmployeeFrame>
  );
}

