// React
import React, { useState, useEffect, useContext } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, TextField, Button } from '@material-ui/core';
// Reach Router
import { navigate } from "@reach/router"
// Local
import AppContext from '../AppContext';
import SubmissionFrame from './SubmissionFrame';
import formPageStyles from '../shared/style/FormPageStyles';
import { MyRadioGroup, MySelectList } from '../shared/controls';
import SectionHeaderRow from '../shared/SectionHeaderRow';


const styles = (theme) => ({
});
const mergeStyles = (theme) => {
  const local = styles(theme);
  const form = formPageStyles(theme);
  return Object.assign(form, local);
}
const useStyles = makeStyles(mergeStyles);


export default function SubmissionHome({submission, clients}) {
    const {firebase, db, reference, loggedInUser} = useContext(AppContext);
  	const refClientStatus = reference['client-status'];
  	const clientStatusOptions = Object.keys(refClientStatus)
	                      			  .map( value => { return { value: value, label: refClientStatus[value].label, step: refClientStatus[value].step } })
	                      			  .sort( (a,b) => a.step - b.step );
	const classes = useStyles();
  	const [isDirty, setIsDirty] = useState(false);
  	const [title, setTitle] = useState(undefined);
  	const [clientStatus, setClientStatus] = useState(undefined);
  	const [clientStatusNote, setClientStatusNote] = useState(undefined);
  	const [linkedClients, setLinkedClients] = useState(undefined);
  
  	useEffect(() => {
  		if( submission.shared === undefined ) {
  			setTitle(undefined);
  			setClientStatus(undefined);
  			setClientStatusNote(undefined);
  			setLinkedClients(undefined);
  		}
  		else {
  			setTitle(submission.private.title);
  			setClientStatus(submission.shared.clientStatus);
  			setClientStatusNote(submission.shared.clientStatusNote);
  			setLinkedClients(submission.shared.clients || []);
  		}
  	},[submission]);


	const handleSave = () => {
		if( submission.id === undefined ) {
		    db.collection('submissions').add({
		      createdBy: loggedInUser.uid,
		      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
		      title: title,
		    })
		    .then( (docRef) => {
		      const newSubmissionId = docRef.id;
		      const newSubmissionShared = {};
		      if( clientStatus !== undefined ) newSubmissionShared.clientStatus = clientStatus;
		      if( clientStatusNote !== undefined ) newSubmissionShared.clientStatusNote = clientStatusNote;
		      newSubmissionShared.clients = linkedClients;
		      db.collection('submissions_shared').doc(newSubmissionId).set(newSubmissionShared)
		      .then( (docRef) => {
		        console.log('Created new submission', newSubmissionId);
		        navigate('/submissions/' + newSubmissionId);
		      })
		      .catch( (error) => {
		        console.error("Error adding submission_shared", error);
		      })
		    })
		    .catch( (error) => {
		      console.error("Error adding submission", error);
		    })
		}
		else {
			const toUpdatePrivate = {
				title: title || firebase.firestore.FieldValue.delete()
			};
			db.collection('submissions').doc(submission.id).update(toUpdatePrivate).then( (result) => {
				setIsDirty(false);
			})
		    .catch( (error) => {
		      console.error("Error saving submission private.", error);
		    });

			const toUpdateShared = {
				clientStatus: clientStatus || firebase.firestore.FieldValue.delete(),
				clientStatusNote: clientStatusNote || firebase.firestore.FieldValue.delete(),
				clients: linkedClients || [],
			};
			db.collection('submissions_shared').doc(submission.id).update(toUpdateShared).then( (result) => {
				setIsDirty(false);
			})
		    .catch( (error) => {
		      console.error("Error saving submission shared.", error);
		    });
		}
	}
	
	const handleChangeTitle = (event) => {
		setTitle(event.target.value);
		setIsDirty(true);
	}
	const handleChangeClientStatus = (value) => {
		setClientStatus(value);
		setIsDirty(true);
	}
	const handleChangeClientStatusNote = (event) => {
		setClientStatusNote(event.target.value);
		setIsDirty(true);
	}
	const handleChangeClients = (newClientIds) => {
		setLinkedClients(newClientIds);
		setIsDirty(true);
	}


	return (
    	<SubmissionFrame submissionId={submission.id} >
  			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}} >
		      	<span style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
			      	{  isDirty &&
			      		<Button variant='contained' color='primary' onClick={() => handleSave()} >Save</Button>
			      	}
			    </span>
		    </div>
  			<FormControl component="fieldset" className={classes.field}>
	            <FormLabel component="legend">Title</FormLabel>
	            <TextField          
	            	id="title"
	            	name="title"
	            	value={title || ''}
	            	type="text"
	            	fullWidth
	            	onChange={(event) => handleChangeTitle(event)}
	            />
	        </FormControl>
	        <FormControl component="fieldset" className={classes.field}>
	        	<FormLabel component="legend">Client status</FormLabel>
	        	<MyRadioGroup value={ clientStatus || ''} 
	                      options={ clientStatusOptions }
	                      onChange={ (value) => handleChangeClientStatus(value) } />
	        </FormControl>
  			<FormControl component="fieldset" className={classes.field}>
	            <FormLabel component="legend">Client status note</FormLabel>
	            <TextField          
	            	id="clientStatusNote"
	            	name="clientStatusNote"
	            	value={clientStatusNote || ''}
	            	type="text"
	            	fullWidth
	            	onChange={(event) => handleChangeClientStatusNote(event)}
	            />
			</FormControl>

            <SectionHeaderRow text='Clients' />
            <MySelectList
            	values={linkedClients}
            	onChange={(newValues) => handleChangeClients(newValues)}
            	options={ clients.map( client => { return { value: client.id, label: client?.private?.name || "<Unnamed>" }; } ) }
            	style={{width: 300}}
            	/>
    	</SubmissionFrame>
  	);
}
