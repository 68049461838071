
export const setDeep = (obj, dotPath, value) => {
    const pathParts = dotPath.split('.');
    let tempObj = obj;
    pathParts.forEach( (part, index, arr) => {
      if( index === arr.length - 1 )
        tempObj[part] = value;
      else
        tempObj = tempObj[part];
    });
}
