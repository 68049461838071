import { gapi } from 'gapi-script';


const createDraft = (to, subject, html) => {
  const email = '' +
    'To: ' + to + '\r\n' +
    'Subject: ' + subject + '\r\n' +
    'Content-Type: text/html\r\n\r\n' +
    html;

  var createDraft = gapi.client.gmail.users.drafts.create({ 
    userId: 'me', 
    message: {
      raw: window.btoa(email).replace(/\+/g, '-').replace(/\//g, '_')
    } 
  });

  return new Promise( (resolve, reject) => createDraft.execute( (draft) => {
    if( draft.code !== undefined )
      reject(new Error(draft.message));
    else
      resolve(draft.message.id);
  }));
}


export default {
  createDraft: createDraft
}