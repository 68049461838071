// React
import React, { useState, useEffect } from 'react';
// Firebase & Google
import { gapi } from 'gapi-script';
// Local
import './App.css';
import SignInPage from './shared/pages/SignInPage';
import NotAuthorizedPage from './shared/pages/NotAuthorizedPage';
import AppMain from './AppMain';
import LoadingOverlay from './shared/LoadingOverlay';
import { getFirebaseConfig, initFirebase, refreshAuthToken } from './shared/firebase/FirebaseUtil';

/*
 *	GAPI and Firebase joint sign-in based on these sources:
 *	https://medium.com/google-cloud/using-google-apis-with-firebase-auth-and-firebase-ui-on-the-web-46e6189cf571
 *	https://github.com/msukmanowsky/gapi-firebase
 */

const GAPI_SCOPES = 'https://www.googleapis.com/auth/gmail.compose'; // 'https://mail.google.com/';
const GAPI_DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest"];

const App = () => {
	const [fb, setFB] = useState(undefined);
  	const [user, setUser] = useState(undefined);
  	const [isEmployee, setIsEmployee] = useState(undefined);
  	const [GAPIStatus, setGAPIStatus] = useState(undefined);
  	const [environment, setEnvironment] = useState(undefined);  

	// Startup
	useEffect(() => {
		getFirebaseConfig().then( (config) => {

			// Load GAPI
		    gapi.load('client:auth2', () => {
		    	setGAPIStatus('loaded');
		    });

		    // Initialize Firebase
			initFirebase(config).then( _fb => {
				setFB(_fb);
				console.log('Firebase is ready.');

				// Wake up the functions if they fell asleep :)
				_fb.functions.wakeUp().catch( error => {
					console.error( "Error waking up functions", error );
				});

				_fb.db.collection('environment').doc('environment').get().then( (doc) => {
					if( ! doc.exists ) {
						console.error('Employee-only environment variables not found in database.');
						return;
					}
					setEnvironment(doc.data());
				});

			}).catch( (error) => {
				console.error("Unable to initialize firebase: ", error);
			});
		});
	},[]);

	// Initialize GAPI
	useEffect(() => {
		if( fb === undefined || environment === undefined || GAPIStatus !== 'loaded' )
			return;

		const gapiClientId = environment.clientId;
		console.log('Retrieved GAPI clientId.', gapiClientId);

		const redirectURL = window.location.protocol + '//' + window.location.hostname + (window.location.port.length > 0 ? ':' + window.location.port : '');
	    gapi.client.init({
	      	apiKey: fb.config.apiKey,
	      	clientId: gapiClientId,
	      	scope: GAPI_SCOPES,
      		discoveryDocs: GAPI_DISCOVERY_DOCS,
      		hosted_domain: 'entrywayloans.com',
	      	ux_mode: 'redirect',
	      	redirect_uri: redirectURL
	    }).then( () => {
	    	console.log('GAPI is ready');
	    	setGAPIStatus('ready');
	    }).catch( (error) => {
	    	console.error("Unable to initialize GAPI.", error);
	    });

	}, [fb, GAPIStatus, environment]);

	// Start authentication
	useEffect(() => {
		if( fb === undefined || GAPIStatus !== 'ready' )
			return;

		const handleSignIn = () => {
		    console.log('Signed into GAPI.');

			// Google User
			const auth2 = gapi.auth2.getAuthInstance();
	  		const currentUser = auth2.currentUser.get();

	  		// Firebase User
		    const authResponse = currentUser.getAuthResponse(true);
		    const credential = fb.firebase.auth.GoogleAuthProvider.credential(
		        authResponse.id_token,
		        authResponse.access_token
		    );
		    fb.auth.signInWithCredential(credential).then( () => {
		    	console.log('Signed into Firebase.');
		    	setUser(fb.auth.currentUser);
		   	}).catch( (error) => {
		    	console.error('Error signing into firebase.', error);
		    });
		}

    	// Listen for the Google Auth state change
		const auth2 = gapi.auth2.getAuthInstance();
		auth2.isSignedIn.listen((isSignedIn) => {
			if( isSignedIn )
				handleSignIn();
		});

		if( auth2.isSignedIn.get() )
			handleSignIn();
		else
			setUser(null);

	}, [fb, GAPIStatus]);

	const signIn = () => {
		const auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn().catch(error => { alert(`sign in error: ${error}`) });
	}

	const signOut = () => {
		setUser(null);

		const auth2 = gapi.auth2.getAuthInstance()
		if( auth2.isSignedIn.get() ) {
			auth2.signOut().then(() => { 
				console.log('Signed out of GAPI.');
				if( fb.auth.currentUser === null )
					console.log('Not signed into Firebase.');
				else
					return fb.auth.signOut().then( () => {
						console.log('Signed out of Firebase.');
					});
			});
		}
		else {
			console.log('Not signed into GAPI.');
			if( fb.auth.currentUser === null )
				console.log('Not signed into Firebase.');
			else
				return fb.auth.signOut().then( () => {
					console.log('Signed out of Firebase.');
				});
		}
	}


	useEffect(() => {
		if( ! (fb === undefined || user === undefined || user === null) ) {
			fb.functions.setIsEmployeeClaim().then( (result) => {
				refreshAuthToken(user).then( idToken => { 
					console.log('Set employee claim & refreshed token');
					setIsEmployee(idToken?.claims?.isEmployee);
				});
			})
			.catch( (error) => {
				console.error("Error in refreshIsEmployeeClaim", error);
			});
		}
	}, [fb, user]);

	// Render
	if( fb === undefined || GAPIStatus !== 'ready' || user === undefined || environment === undefined )
		return ( <LoadingOverlay open={true} /> );
	else if( user === null )
		return ( <SignInPage signIn={signIn} /> );
	else if( isEmployee === undefined )
		return ( <LoadingOverlay open={true} /> );
	else if( (!user.email.endsWith('@entrywayloans.com')) || isEmployee === false )
		return ( <NotAuthorizedPage auth={fb.auth} /> );
	else
		return ( <AppMain fb={fb} user={user} signOut={signOut} environment={environment} /> );

}

export default App;
