// React
import React, { useContext } from 'react';
// Material UI
//import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Reach Router
import { navigate } from "@reach/router"
// Local
import AppContext from '../AppContext';
import EmployeeFrame from '../EmployeeFrame';
import CardListSection from '../shared/CardListSection';


//const styles = (theme) => ({});
//const useStyles = makeStyles(styles);


export default function Submissions({clients, submissions}) {
  const {reference} = useContext(AppContext);
  const refClientStatus = reference['client-status'];
  //const classes = useStyles();

  const handleAddSubmission = () => {
     navigate('submissions/new');
  }

  const getCardRight = (value) => {
    const status = value?.shared?.clientStatus || 'application';
    return ( <Typography variant="body1"
                        style={{ textTransform: 'uppercase',
                                 color: refClientStatus[status].color }} 
                           >{refClientStatus[status].label}</Typography> );
  }

  return (
    <EmployeeFrame title='Submissions' backLabel='Home' backTo='/' >

      <CardListSection
          title='Loan Applications'
          onAdd={() => handleAddSubmission()}
          getCardTitle={(value) => value?.private?.title || "New Application" }
          getCardUrl={(value) => '/submissions/' + value.id }
          getCardRight={(value) => getCardRight(value)}
          values={submissions}
          />   

    </EmployeeFrame>
  );
}

