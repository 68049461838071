// React
import React, { useState, useContext, useEffect } from 'react';
// Reach Router
import { navigate } from '@reach/router';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormLabel, Button, Typography, TextField } from '@material-ui/core';
// Local
import AppContext from '../AppContext';
import FullScreenPopup from '../shared/FullScreenPopup';
import GoogleAPIUtil from '../shared/GoogleAPIUtil';
import SectionHeaderRow from '../shared/SectionHeaderRow';


import formPageStyles from '../shared/style/FormPageStyles';
import CardListSection from '../shared/CardListSection';


const styles = (theme) => ({
    container: {
      // Children
      padding: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(3),
      },

      backgroundColor: '#FFFFFF',
    },

    footerButton: {
      backgroundColor: theme.palette.grey[300],
      width: '110px',
    },

    emailField: {
      width: '200px',
    },
    
  aButton: {
    marginTop: theme.spacing(2),
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});
const mergeStyles = (theme) => {
  const local = styles(theme);
  const form = formPageStyles(theme);
  return Object.assign(form, local);
}
const useStyles = makeStyles(mergeStyles);


const invitationEmailTemplate = 
  'Welcome to Entryway Loans!\r\n' +
  '\r\n' +
  'Please <a href="{{URL_TO_REGISTER}}">register</a> for access.';


export default function Client({clientId, clients, submissions}) {
  const {db, loggedInUser, firebase, environment, reference} = useContext(AppContext);
  const classes = useStyles();
  const refClientStatus = reference['client-status'];
  const [client, setClient] = useState(undefined);
  const [linkedSubmissions, setLinkedSubmissions] = useState(undefined);

  useEffect(() => {
    if( clientId === undefined || clients === undefined )
      setClient(undefined);
    else {
      const client = clients.find(e => e.id === clientId);
      if( client === undefined )
        setClient('not-found');
      else
        setClient(client);
    }
  }, [clientId, clients]);

  useEffect(() => {
    if( clientId === undefined || submissions === undefined )
      setLinkedSubmissions(undefined);
    else {
      const linkedSubmissions = submissions.filter( submission => {
        return submission?.shared?.clients !== undefined && submission.shared.clients.includes(clientId);
      });
      setLinkedSubmissions(linkedSubmissions);
    } 
  }, [clientId, submissions]);

  const handleClose = () => {
    navigate('/clients');
  }
  const handleSaveAndClose = () => {
    const clientToSave = JSON.parse(JSON.stringify(client.private));
    if( clientToSave.createdAt ) delete clientToSave.createdAt;
    if( clientToSave.createdBy ) delete clientToSave.createdBy;

    db.collection('clients').doc(clientId).update(clientToSave)
    .then( (docRef) => {
      handleClose();
    })
    .catch( (error) => {
      console.error("Error saving client.", error);
    })
  }
  const handleOnChangeEvent = (event) => {
    event.preventDefault();
    handleOnChange(event.target.name, event.target.value);
  }
  const handleOnChange = (name, value) => {
    let newClient = JSON.parse(JSON.stringify(client));
    const arrName = name.split('.');
    let obj = newClient;
    arrName.forEach( (name, index, array) => {
      if( index === array.length-1 )
        obj[name] = value;
      else if( ! obj[name] )
        obj[name] = {};
      obj = obj[name];
    });
    setClient( newClient );
  };

  const handleInvite = () => {
    if( client?.private?.email === undefined )
      console.error('No email address provided.');

    // Create a draft invitation email in user's gmail account
    const registrationUrl = 'https://' + environment.urls.client + '?clientId=' + clientId;
    const body = invitationEmailTemplate.replace('{{URL_TO_REGISTER}}', registrationUrl);
    GoogleAPIUtil.createDraft( client.private.email, 'Welcome to Entryway Loans', body).then( (draftId) => {
      console.log('Draft email created', draftId);

      // Open draft in gmail in a new tab
      const url = "https://mail.google.com/mail/ca/u/1/#drafts?compose=" + draftId;
      window.open(url, "_blank", "noopener");
    }).catch( (error) => {
      console.error("Error creating draft email", error);
    });
  }

  const handleStartNewApplication = () => {
    db.collection('submissions').add({
      createdBy: loggedInUser.uid,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
    .then( (docRef) => {
      const submissionId = docRef.id;
      const newSubmissionShared = {};
      newSubmissionShared.clients = [clientId];
      db.collection('submissions_shared').doc(submissionId).set(newSubmissionShared)
      .then( (docRef) => {
        console.log('Created new submission', submissionId);
        navigate('/submissions/' + submissionId);
      })
      .catch( (error) => {
        console.error("Error adding submission_shared", error);
      })
    })
    .catch( (error) => {
      console.error("Error adding submission", error);
    })
  };

  const getSubmissionCardRight = (value) => {
    const status = value?.shared?.clientStatus || 'application';
    return ( <Typography variant="body1"
                        style={{ textTransform: 'uppercase',
                                 color: refClientStatus[status].color }} 
                           >{refClientStatus[status].label}</Typography> );
  }


  const footerRight = [<Button key={1} onClick={(event) => handleClose()} className={classes.footerButton}>Cancel</Button>,
                      <Button key={2} color="primary" onClick={(event) => handleSaveAndClose()} className={classes.footerButton}>Close</Button>];

  if( client === undefined )
    return ( <FullScreenPopup title='Loading' footerRight={footerRight} onCancel={() => handleClose()} ><Typography variant='body1'>Loading...</Typography></FullScreenPopup> );
  else if( client === 'not-found' )
    return ( <FullScreenPopup title='Loading' footerRight={footerRight} onCancel={() => handleClose()} ><Typography variant='body1'>Not found</Typography></FullScreenPopup> );
  else {
    return (
      <FullScreenPopup  title={client.private.name || '<Unnamed>'} footerRight={footerRight} onCancel={() => handleClose()} >
        <div className={classes.container} >
          <div className={classes.pageContent} >
            <SectionHeaderRow text='Details' />

            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Name</FormLabel>
              <TextField          
                type="text"
                name="private.name"
                value={client.private.name || ''}
                className={classes.textField} 
                onChange={ (event) => handleOnChangeEvent(event) }
              />
            </FormControl>
            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Email</FormLabel>
              <TextField          
                type="text"
                name="private.email"
                value={client.private.email || ''}
                className={classes.emailField} 
                onChange={ (event) => handleOnChangeEvent(event) }
              />
            </FormControl>
            <Typography variant='body1'>UID: {client.private.uid}</Typography>

          <Button onClick={(event) => handleInvite()} 
                  variant='outlined'
                  className={classes.aButton}
                  color='primary'
                  disabled={client?.private?.email === undefined} >Invite</Button>
          <br /><br />
          <Button onClick={(event) => navigate(clientId + '/application')} 
                  variant='outlined'
                  className={classes.aButton}
                  color='primary' >Application</Button>


          <CardListSection
              title='Loan Applications'
              onAdd={() => handleStartNewApplication()}
              getCardTitle={(value) => value?.private?.title || "New Application" }
              getCardUrl={(value) => '/submissions/' + value.id }
              getCardRight={(value) => getSubmissionCardRight(value)}
              values={linkedSubmissions}
              /> 

          </div>
        </div>
      </FullScreenPopup>
    );
  }
}
