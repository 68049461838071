// React
import React, { useState, useEffect } from 'react';
// Reach Router
import { Router, navigate } from "@reach/router"
// Local
import AppContext from './AppContext';
import Home from './Home';
import Submissions from './Submissions/Submissions';
import Submission from './Submissions/Submission';
import Clients from './Clients/Clients';
import Client from './Clients/Client';
import ClientApplicationPage from './Clients/ClientApplicationPage';
import NotFound from './NotFound';
import LoadingOverlay from './shared/LoadingOverlay';
import { handleFirestoreUpdate, handleFirestoreUpdateToArray } from './shared/firebase/FirebaseUtil';


const AppMain = ({fb, user, signOut, environment}) => {
  const {firebase, db, storage, functions} = fb;

  const [reference, setReference] = useState(undefined);
  const [clients, setClients] = useState(undefined);
  const [submissions, setSubmissions] = useState(undefined);    // Form:  [ { 'id': id, private: {}, shared: {} } ]

  // Get data - once user and connection are found 
  useEffect(() => {
    var unsubSubmissions = db.collection('submissions')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setSubmissions, querySnapshot, 'private') );

    var unsubSubmissionsShared = db.collection('submissions_shared')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setSubmissions, querySnapshot, 'shared') );

    var unsubClients = db.collection('clients')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setClients, querySnapshot, 'private') );

    var unsubClientShareds = db.collection('clients_shared')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setClients, querySnapshot, 'shared') );

    var unsubReference = db.collection('reference')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdate(setReference, querySnapshot) );

    return function cleanup() {
      unsubSubmissions();
      unsubSubmissionsShared();
      unsubClients();
      unsubClientShareds();
      unsubReference();
    }
  }, [db]);


  if( fb === undefined || reference === undefined || user === undefined )
    return ( <LoadingOverlay open={true} /> );
  else
    return (
      <AppContext.Provider value={{ 
                    firebase: firebase, 
                    db: db, 
                    storage: storage,
                    functions: functions,
                    loggedInUser: user, 
                    reference: reference,
                    environment: environment,
                    doSignOut: () => { navigate('/'); signOut(); }
                    }}>
        <Router style={{height: '100%'}}>
          <Home path='/'                                 clients={clients} submissions={submissions} />
          <Submissions path='submissions'                clients={clients} submissions={submissions} />
          <Submission path='submissions/:submissionId/*' clients={clients} submissions={submissions} />
          <Clients path='clients'                        clients={clients} />
          <Client  path='clients/:clientId'              clients={clients} submissions={submissions} />
          <ClientApplicationPage  path='clients/:clientId/application'       clients={clients} />
          <NotFound default />
        </Router>
      </AppContext.Provider>
    );
}

export default AppMain;