// React
import React, { useContext } from 'react';
// Material UI
//import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
// Local
import AppContext from './AppContext';
import EmployeeFrame from './EmployeeFrame';
import CardListSection from './shared/CardListSection';


//const styles = (theme) => ({});
//const useStyles = makeStyles(styles);


export default function Home({clients, submissions}) {
  const {reference} = useContext(AppContext);
  const refClientStatus = reference['client-status'];
//  const classes = useStyles();


  const getSubmissionCardRight = (value) => {
    const status = value?.shared?.clientStatus || 'application';
    return ( <Typography variant="body1"
                        style={{ textTransform: 'uppercase',
                                 color: refClientStatus[status].color }} 
                           >{refClientStatus[status].label}</Typography> );
  }


  return (
    <EmployeeFrame title='Entryway Loans' backLabel={undefined} backTo={undefined} >

      <CardListSection
            title='Loan Applications'
            getCardTitle={(value) => value?.private?.title || "New Application" }
            getCardUrl={(value) => '/submissions/' + value.id }
            getCardRight={(value) => getSubmissionCardRight(value)}
            values={submissions}
            />  

      <CardListSection
          title='Clients'
          getCardTitle={(value) => value?.private?.name || '<Unnamed>' }
          getCardUrl={(value) => '/clients/' + value.id }
          values={clients}
          />   

    </EmployeeFrame>
  );
}

