import React, { useState, useEffect, useContext } from 'react';
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography, FormControl, FormLabel } from '@material-ui/core';
// Reach Router
import { navigate } from "@reach/router"
// Local
import AppContext from '../AppContext';
import LoadingOverlay from '../shared/LoadingOverlay';
import FullScreenPopup from '../shared/FullScreenPopup';
import LoanApplicationForm from '../shared/forms/LoanApplicationForm';
import { MyRadioGroup } from '../shared/controls';



const styles = (theme) => ({
    header: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },

    
    container: {
      backgroundColor: '#f0f0f0',
    },

    main: {
      // Width
      width: '100%',
      marginLeft: 0,
      marginRight: 0,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.up('md')]: {
        width: theme.breakpoints.values.md - theme.spacing(2) * 2,
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: 0,
        paddingRight: 0,
      },

      display: 'flex',
      flexDirection: 'column',
    },

  footerSaveButton: {
    width: '90px',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});
const useStyles = makeStyles(styles);



export default function LoanApplicationPage({submission}) {
  const classes = useStyles();
  const {db} = useContext(AppContext);

  const [showLoading, setShowLoading] = useState(false);
  const [applicationData, setApplicationData] = useState(undefined);
  const [status, setStatus] = useState(undefined);
  const [isDirty, setIsDirty] = useState(false);


  useEffect(() => {
    if( submission?.shared === undefined ) {
      setApplicationData(undefined);
      setStatus(undefined);
    }
    else {
      setApplicationData( submission?.shared?.application?.data || {} );
      setStatus( submission?.hared?.application?.status || 'new' );
    }
  }, [submission]);

  const handleClose = () => {
    save().then(() => navigate(-1));
  }

  const handleOnChange = (name, value) => {
    const newApplicationData = JSON.parse(JSON.stringify(applicationData));
    newApplicationData[name] = value;
    setApplicationData(newApplicationData);
    setIsDirty(true);
  }

  const save = () => {
    if( applicationData === undefined )
      throw new Error('Application data is undefined.');
    if( ! isDirty )
      return new Promise( (resolve) => resolve() );

    setShowLoading(true);
    
    const updateData = {
      'application.status': status,
      'application.data': applicationData
    };
    return db.collection('submissions_shared').doc(submission.id).update(updateData).then()
      .catch( (error) => {
        console.error('Error saving application data', error);
    }).finally( () => setShowLoading(false) );
  }

  if( applicationData === undefined )
    return ( 
        <LoadingOverlay open={true} />
    );
  else {
    const isReadOnly = false;
    
    return (
      <FullScreenPopup  title="Loan Application" onCancel={() => handleClose()}
          footerRight={<Button onClick={(e) => handleClose()} color='primary' variant='outlined' className={classes.footerSaveButton} >Close</Button>} >
        
        <div className={classes.container} >
          <div className={classes.main} >

            <Typography variant="h3" >Status</Typography>
            <FormControl component="fieldset" className={classes.field}>
              <FormLabel component="legend">Status</FormLabel>
              <MyRadioGroup value={status || ''} 
                            options={ [ {value:'new', label:'New'},
                                        {value:'submitted', label:'Submitted'}, 
                                        {value:'accepted', label:'Accepted'}, 
                                        {value:'rejected', label:'Needs Attention'} ] }
                            onChange={ (value) => { setStatus(value); setIsDirty(true); } } />
            </FormControl>

            <LoanApplicationForm value={applicationData} onChange={(name, value) => handleOnChange(name, value)} disabled={isReadOnly} />

          </div>

        </div>

        <LoadingOverlay open={showLoading} />
      </FullScreenPopup>
    );
  }

}
