// React
import React, { useState } from 'react';
// Reach Router
import { Link, useLocation } from "@reach/router"
// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';
// Local
import EmployeeFrame from '../EmployeeFrame';


const styles = (theme) => ({
  tabRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});
const useStyles = makeStyles(styles);

const tabs = [
  { label: 'Basics', url: '' },
  { label: 'Submission', url: 'submission' },
];

export default function SubmissionFrame({children, submissionId}) {
  const classes = useStyles();
  const location = useLocation();
  const subpath = location.pathname.slice(14 + submissionId.length);
  const [tabIndex, setTabIndex] = useState( tabs.findIndex( (tab) => tab.url === subpath ) );

  return (
    <EmployeeFrame title='Loan Application' backLabel='Subs' backTo='/submissions' >
      <Tabs
        indicatorColor="primary"
        value={tabIndex}
        onChange={(event, newValue) => setTabIndex(newValue)}
        className={classes.tabRow}
        variant='fullWidth'
      >
        { tabs.map( (tab) => ( <Tab key={tab.url} label={tab.label} component={Link} to={subpath.length === 0 ? tab.url : '../' + tab.url} /> ) ) }
      </Tabs>
      {children}
    </EmployeeFrame>
  );
}
