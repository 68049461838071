// React
import React, { useState, useEffect, useContext } from 'react';
// Reach Router
import { Router } from "@reach/router"
// Material UI
import { } from '@material-ui/core';
// Local
import AppContext from '../AppContext';
import EmployeeFrame from '../EmployeeFrame';
import SubmissionHome from './SubmissionHome';
import SubmissionDocuments from './SubmissionDocuments';
import LoanApplicationPage from './LoanApplicationPage';
import ClientDocument from './ClientDocument';
import ClientDocumentRequest from './ClientDocumentRequest';
import ClientUploadClass from '../shared/model/ClientUploadClass';
import { handleFirestoreUpdateToArray } from '../shared/firebase/FirebaseUtil';


export default function Submission({submissionId, submissions, clients}) {
  const {db, globals} = useContext(AppContext);
  const [submission, setSubmission] = useState(undefined);
  const [clientUploads, setClientUploads] = useState(undefined);

  useEffect(() => {
    if( submissionId === undefined || submissions === undefined )
      setSubmission(undefined);
    else if( submissionId === 'new' ) {
      console.log('Starting with new submission.');
      setSubmission({});
    }
    else {
      const s = submissions.find( e => e.id === submissionId );
      if( s === undefined )
        setSubmission('not found');
      else { 
        console.log('Staring with submission ' + submissionId);
        setSubmission(s);
      }
    }
  }, [submissionId, submissions]);

  // Get client_uploads from database
  useEffect(() => {
    var unsub = db.collection('submissions_shared').doc(submissionId).collection('client_uploads')
      .onSnapshot( (querySnapshot) => handleFirestoreUpdateToArray(setClientUploads, querySnapshot, 'shared', ClientUploadClass) );
   
    return function cleanup() {
        unsub();
    }
  }, [db, submissionId, globals]);


  if( submission === undefined )
    return ( <EmployeeFrame title='Loan Application' backLabel='Home' backTo='/' ><div>Loading submission</div></EmployeeFrame> );
  else if( submission === 'not found' )
    return ( <EmployeeFrame title='Loan Application' backLabel='Home' backTo='/' ><div>Submission not found.</div></EmployeeFrame> );
  else
    return (
      <Router style={{height: '100%'}}>
        <SubmissionHome path='/' submission={submission} clients={clients} />
        <SubmissionDocuments path='submission' submission={submission} clientUploads={clientUploads} clients={clients} />
        <ClientDocumentRequest path='client-upload-requests/:clientUploadId' submissionId={submissionId} clientUploads={clientUploads} />
        <ClientDocument path='client-uploads/:clientUploadId' submissionId={submissionId} clientUploads={clientUploads} />
        <LoanApplicationPage path='/application' submission={submission} />
      </Router>
    );
}


//<ClientDocument path='tasks/:taskId' test={submissionShareds.tasks[taskId]} submissionId={submissionId} submission={submission} submissionClient={submissionClient} submissionShared={submissionShared} />