// React
import React, { } from 'react';
// Material UI
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';


const MyYesNoDialog = ({ open, title, message, onYes, onNo }) => {

	return ( 
		<Dialog open={open} onClose={() => onNo()} >
    	<DialogTitle>{title}</DialogTitle>
    	<DialogContent>
      		<DialogContentText>{message}</DialogContentText>
    	</DialogContent>
    	<DialogActions>
          <Button onClick={() => onYes()} color="secondary" autoFocus>Yes</Button>
          <Button onClick={() => onNo()} color="primary" variant='contained' autoFocus>No</Button>
    	</DialogActions>
  	</Dialog>
	);
}

export default MyYesNoDialog;
